/*
 * @Author: zhoutao mrzater@163.com
 * @Date: 2024-07-08 15:16:25
 * @LastEditors: zhoutao mrzater@163.com
 * @LastEditTime: 2024-07-09 11:53:27
 * @FilePath: /mediatom-web/src/viewsForManage/FunctionList/EditWarning/ConditionForm/ruleIdList.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
export const ruleIdList = [
  {
    name: '流量请求',
    id: 1,
    target: [1, 2]
  },
  {
    name: '广告请求',
    id: 2,
    target: [3, 4, 5]
  },
  {
    name: '流量填充',
    id: 3,
    target: [1, 2]
  },
  {
    name: '广告填充',
    id: 4,
    target: [3, 4, 5]
  },
  {
    name: '预估收益',
    id: 5,
    target: [1, 2, 3, 4, 5]
  },
  {
    name: '展示率',
    id: 6,
    target: [1, 2, 3, 4, 5]
  },
  {
    name: '点击率',
    id: 7,
    target: [1, 2, 3, 4, 5]
  },
  {
    name: '预估eCPM',
    id: 8,
    target: [1, 2, 3, 4, 5]
  },
  {
    name: '竞价响应eCPM',
    id: 9,
    target: [1, 2, 3, 4, 5]
  }
]
