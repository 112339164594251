<!--
 * @Author: zhoutao mrzater@163.com
 * @Date: 2024-07-03 14:22:00
 * @LastEditors: zhoutao mrzater@163.com
 * @LastEditTime: 2024-08-13 17:49:05
 * @FilePath: /mediatom-web/src/viewsForManage/FunctionList/EditWarning/index.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
  <div class="edit-warning-container">
    <div class="inner">
      <GoBack @goBack="goBack" title1="高级功能" title2="预警管理" :title3="`${isAdd ? '添加' : '修改'}预警规则`"/>
      <a-form-model
        ref="ruleForm"
        class="ruleForm"
        :model="formquery"
        :rules="rules"
        v-bind="layout"
        :colon="false"
        labelAlign="left"
      >
        <a-spin class="edit-maskrule-spin" :spinning="isLoading">
          <BaseForm :isAdd="isAdd" :query="formquery" @changeQuery="changeQuery" @changeTarget="changeTarget"/>
          <TargetListForm
            :dspList="dspList"
            @changePublisher="changePublisher"
            :placeList="placeList"
            :supplierList="supplierList"
            :isAdd="isAdd"
            :query="formquery"
            @changeQuery="changeQuery"/>
          <PeriodForm :isAdd="isAdd" :query="formquery" @changeQuery="changeQuery"/>
          <ConditionForm :isAdd="isAdd" :query="formquery" @changeQuery="changeQuery"/>
          <ContactForm :isAdd="isAdd" :query="formquery"/>
        </a-spin>
      </a-form-model>
      <div class="footer-btn">
        <div class="btn-box">
          <a-button type="primary" @click="handleSubmit">{{ isAdd ? '添加' : '保存' }}</a-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import GoBack from '@/components/common/GoBack'
import BaseForm from './BaseForm'
import TargetListForm from './TargetListForm'
import getListMix from '@/mixins/getListForManage'
import PeriodForm from './PeriodForm'
import ConditionForm from './ConditionForm'
import ContactForm from './ContactForm'
import { addWarning, getWarningInfo, updateWarning } from '@/apiForManage/functionList/warning'
export default {
  name: 'EditWarning',
  components: {
    GoBack,
    BaseForm,
    TargetListForm,
    PeriodForm,
    ConditionForm,
    ContactForm
  },
  mixins: [getListMix],
  data () {
    return {
      isAdd: false,
      formquery: {},
      formquerytemp: {
        name: undefined,
        target: 1,
        publisherIdList: [],
        placeIdList: [],
        dspType: 0,
        dspIdList: [],
        frequency: 1,
        triggerDayList: [],
        earlyWarningRuleList: [],
        triggerCondition: 0,
        noticTypes: [],
        phoneList: [],
        emailList: []
        // noticeType: 1,
        // noticeValueList: []
      },
      rules: {},
      layout: {
        labelCol: { span: 3 },
        wrapperCol: { span: 15 }
        },
      isLoading: false
    }
  },
  watch: {
    $route: {
      async handler (val) {
        this.isAdd = val.name === 'addwarning'
        await this.getSupplierList()
        await this.getDspList()
        this.dspList.forEach((item) => {
        item.searchId = item.platPlaceId
        item.id = '' + item.id
      })
        let info
        // 数据来源
        if (this.isAdd) {
          // 本地模版
          info = JSON.parse(JSON.stringify(this.formquerytemp))
        } else {
          // 远程获取
          const { query = {} } = this.$route
          const { id } = query
          const { data = {} } = await getWarningInfo({
            id
          })
          info = data
          const { earlyWarningNoticeVoList = [] } = info
          info.noticTypes = []
          info.emailList = []
          info.phoneList = []
          earlyWarningNoticeVoList.forEach((item) => {
            info.noticTypes.push(+item.noticeType)
            if (+item.noticeType === 1) {
              info.emailList = item.noticeValueList
            } else if (+item.noticeType === 2) {
              info.phoneList = item.noticeValueList
            }
          })
        }
        await this.getPlaceList(info.publisherIdList)
        this.formquery = info
      },
      deep: true,
      immediate: true
    }
  },
  methods: {
    goBack () {
      this.$router.push({
        name: 'warning'
      })
    },
    changeTarget () {
      this.formquery.earlyWarningRuleList = []
      this.$refs.ruleForm.clearValidate()
    },
    changePublisher (ids) {
      this.formquery.placeIdList = []
      this.getPlaceList(ids)
    },
    changeQuery (query) {
      this.formquery = JSON.parse(JSON.stringify(query))
    },
    handleSubmit () {
      this.$refs.ruleForm.validate(async (valid) => {
        if (valid) {
          if (this.formquery.earlyWarningRuleList.length === 0) {
            return this.$message.error('请添加预警条件')
          }
          this.formquery.earlyWarningNoticeVoList = []
          this.formquery.noticTypes.forEach((item) => {
            const obj = {
              noticeType: item,
              noticeValueList: []
            }
            if (+item === 1) {
              obj.noticeValueList = this.formquery.emailList
            } else if (+item === 2) {
              obj.noticeValueList = this.formquery.phoneList
            }
            this.formquery.earlyWarningNoticeVoList.push(obj)
          })
          this.isLoading = true
          if (this.isAdd) {
            // 添加
            const resp = await addWarning(this.formquery)
            if (resp.code === 200) {
              this.$message.success('添加成功！')
              this.$router.push({
                name: 'warning'
              })
            }
          } else {
            // 编辑
            const resp = await updateWarning(this.formquery)
            if (resp.code === 200) {
              this.$message.success('编辑成功！')
              this.$router.push({
                name: 'warning'
              })
            }
          }
        }
      })
    }
  }
}
</script>

<style lang="less" scoped>
.edit-warning-container {
  position: relative;
  // min-width: 1280px;
  min-height: 500px;
  height: calc(100vh - 60px);
  padding: calc(60px + @mediumMargin) @mediumMargin @mediumMargin @mediumMargin;

  .inner{
    border: 1px solid;
    height: 100%;
    background-color: #fff;
    border-radius: @mediumRadius;
    box-shadow: @modelShadow;
    border: 1px solid @modelBorderColor;
    position: relative;
  }
  .ruleForm {
    height: calc(100% - 70px);
    padding-left: 100px;
    padding-top: 40px;
    // padding-right: 520px;
    overflow-y: auto;
  }
  .footer-btn{
    height: 60px;
    border-top: 1px solid #eee;
    width: 100%;
    .btn-box{
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      padding: 0 60px;
      button{
        width: 132px;
      }
    }
  }
}
</style>
