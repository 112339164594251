<template>
  <div class="target-list-form-wrapper">
    <template>
      <!-- 供应商 -->
      <a-form-model-item
        v-if="+formquery.target === 1 || +formquery.target === 2 || +formquery.target === 4 || +formquery.target === 5"
        prop="publisherIdList"
        label="供应商"
        :rules="[{ required: true, message: '请选择供应商', trigger: 'change' }]"
      >
        <m-select-more
          style="width: 420px"
          class="search_item"
          v-model="formquery.publisherIdList"
          :allData="supplierList"
          :searchById="true"
          :hasIcon="false"
          :showId="true"
          width="450px"
          label="供应商"
          :showLabel="false"
          @change="changeSupplier"
        />
      </a-form-model-item>
      <!-- 广告位 -->
      <a-form-model-item
        v-if="+formquery.target === 2 || +formquery.target === 5"
        prop="placeIdList"
        label="广告位"
        :rules="[{ required: true, message: '请选择广告位', trigger: 'change' }]"
      >
        <m-select-more
          style="width: 420px"
          class="search_item"
          v-model="formquery.placeIdList"
          :allData="placeList"
          :searchById="true"
          :hasIcon="false"
          :showId="true"
          width="450px"
          label="广告位"
          :showLabel="false"
        />
      </a-form-model-item>
    </template>
    <!-- 广告源范围 -->
    <a-form-model-item
      prop="dspType"
      label="广告源范围"
      v-if="+formquery.target === 3"
    >
      <a-radio-group v-model="formquery.dspType">
        <a-radio :value="0"> 部分 </a-radio>
        <a-radio :value="1"> 全部 </a-radio>
      </a-radio-group>
    </a-form-model-item>
    <!-- 广告源 -->
    <a-form-model-item
      v-if="(+formquery.target === 3 && formquery.dspType === 0) || +formquery.target === 4 || +formquery.target === 5"
      prop="dspIdList"
      label="广告源"
      :rules="[{ required: true, message: '请选择广告源', trigger: 'change' }]"
    >
      <m-select-more
        style="width: 420px"
        class="search_item"
        v-model="formquery.dspIdList"
        :allData="dspList"
        :searchById="true"
        :hasIcon="false"
        :showId="true"
        width="450px"
        label="广告源"
        :showLabel="false"
        :showSelectedPart="true"
        :canSelectAll="true"
      />
    </a-form-model-item>
  </div>
</template>

<script>
import { mapState } from 'vuex'
export default {
  name: 'TargetListForm',
  data () {
    return {
      // 表单默认值
      formqueryTemp: {
        publisherIdList: [],
        placeIdList: [],
        dspIdList: []
      },
      ismanager: process.env.VUE_APP_ISMANAGER === 'true'
    }
  },
  computed: {
    formquery: {
      get () {
        return this.query
      },
      set (val) {
        const formquery = {
          ...this.formqueryTemp,
          ...JSON.parse(JSON.stringify(val))
        }
        this.$emit('changeQuery', formquery)
      }
    },
    ...mapState({
      // 角色
      role: (state) => state.user.roles[0],
      title: (state) => state.autoweb.title,
      adminUserId: (state) => state.user.adminUserId,
      currentLoginId: (state) => state.user.id
    }),
    isAdmin () {
      if (this.role === 'SUPER_ADMIN' || +this.adminUserId === 1) {
        return true
      }
      return false
    }
  },
  props: {
    query: {
      default: () => ({
        publisherIdList: [],
        placeIdList: [],
        dspIdList: []
      }),
      type: Object
    },
    isAdd: {
      default: false,
      type: Boolean
    },
    supplierList: {
      default: () => [],
      type: Array
    },
    placeList: {
      default: () => [],
      type: Array
    },
    dspList: {
      default: () => [],
      type: Array
    }
  },
  methods: {
    changeSupplier (ids) {
      this.$emit('changePublisher', ids)
    },
    changeQuery () {
      this.$emit('changeQuery', this.formquery)
    }
  }
}
</script>

<style lang="less" scoped>
.target-list-form-wrapper {
  background: #fff;
  border-radius: 10px;
  ::v-deep {
    .has-error .ant-select-selection {
      border-color: var(--compBorderColor);
    }
    .has-error .ant-select-arrow {
      color: rgba(0, 0, 0, 0.25);
    }
  }
}
</style>
